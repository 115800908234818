/* HelveticaNeueCyr-Thin */
@font-face {
  font-family: "Helvetica Neue Cyr";
  src: url("../assets/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue Cyr";
  src: url("../assets/fonts/HelveticaNeueCyr/HelveticaNeueCyr-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

/* HelveticaNeueCyr-UltraLight */
@font-face {
  font-family: "Helvetica Neue Cyr";
  src: url("../assets/fonts/HelveticaNeueCyr/HelveticaNeueCyr-UltraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue Cyr";
  src: url("../assets/fonts/HelveticaNeueCyr/HelveticaNeueCyr-UltraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

/* HelveticaNeueCyr-Light */
@font-face {
  font-family: "Helvetica Neue Cyr";
  src: url("../assets/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue Cyr";
  src: url("../assets/fonts/HelveticaNeueCyr/HelveticaNeueCyr-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

/* HelveticaNeueCyr-Roman (often treated as “Regular”) */
@font-face {
  font-family: "Helvetica Neue Cyr";
  src: url("../assets/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Roman.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue Cyr";
  src: url("../assets/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

/* HelveticaNeueCyr-Medium */
@font-face {
  font-family: "Helvetica Neue Cyr";
  src: url("../assets/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue Cyr";
  src: url("../assets/fonts/HelveticaNeueCyr/HelveticaNeueCyr-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

/* HelveticaNeueCyr-Bold */
@font-face {
  font-family: "Helvetica Neue Cyr";
  src: url("../assets/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue Cyr";
  src: url("../assets/fonts/HelveticaNeueCyr/HelveticaNeueCyr-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

/* HelveticaNeueCyr-Heavy */
@font-face {
  font-family: "Helvetica Neue Cyr";
  src: url("../assets/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Heavy.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue Cyr";
  src: url("../assets/fonts/HelveticaNeueCyr/HelveticaNeueCyr-HeavyItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

/* HelveticaNeueCyr-Black */
@font-face {
  font-family: "Helvetica Neue Cyr";
  src: url("../assets/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue Cyr";
  src: url("../assets/fonts/HelveticaNeueCyr/HelveticaNeueCyr-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

/*!* Example usage *!*/
/*body {*/
/*  font-family: "Helvetica Neue Cyr", sans-serif;*/
/*}*/
