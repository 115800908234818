@import "fonts";

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  font-family: "Helvetica Neue Cyr", sans-serif;
}

a {
  text-decoration: none;
  color: #004a96;
  font-size: 16px;
  font-weight: 500;
  line-height: 29px;
}

a:hover {
  text-decoration: underline;
}

p.optanon-toggle-display {
  margin: 0;
  cursor: pointer;
}

p.optanon-toggle-display:hover {
  text-decoration: underline;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.h1 {
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

@media (hover: none) {
  .MuiButton-containedSecondary:hover,
  .MuiButton-outlinedPrimary:hover {
    background-color: white;
    color: #004a96;
  }
}
